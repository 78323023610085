import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reading-tracker"
    }}>{`Reading Tracker`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`The Reading Tracker homescreen will detail the patron's most recent reading session item(s). The item(s) are displayed in a carousel format that allows the patron to swipe through the items. `}</li>
      <li parentName="ol">{`Patrons can start a new reading session by selecting 'New Session'. They can pick from viewing their current list of loans or by entering in a ISBN. Once reading progress has been started on an item, it will be added to the carousel under Recently Read Items. `}</li>
    </ol>
    <p><img alt="Reading Tracker Main Screen" src={require("./images/reading_tracker_main.png")} />{` `}<img alt="Reading Tracker Create New Session Screen" src={require("./images/reading_tracker_create_new.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Patrons can also start a new reading session by selecting one of the items in the carousel under Recently Read Items. The Reading Session tracking associated with that item will display. Once in this screen, you can review reading metrics, start a new reading session or log a previous session. `}</li>
    </ol>
    <p><img alt="Reading Tracker Main Screen" src={require("./images/reading_tracker_main.png")} />{` `}<img alt="Reading Tracker Item Display" src={require("./images/reading_tracker_item_display.png")} />{` `}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Logging a previous reading session will require the patron to enter in the page they started reading on, how long they spent reading and the page they finished reading at. The app will ask the patron if they are still reading the book or finished it during the session.`}</li>
    </ol>
    <p><img alt="Reading Tracker Log Previous" src={require("./images/reading_tracker_log_previous.png")} />{` `}<img alt="Reading Tracker Save Previous Session" src={require("./images/reading_tracker_save_previous.png")} />{` `}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`If a new reading session has been started, the Log New Session page displays. Patrons can enter their starting and finishing page. A reading timer is provided to track how long they were reading for. `}<em parentName="li">{`Reading sessions must be longer than a minute in order to save.`}</em>{` The app will ask the patron if they are still reading the book or finished it during the session. `}</li>
    </ol>
    <p><img alt="Reading Tracker Log Session" src={require("./images/reading_tracker_log_session.png")} />{` `}<img alt="Reading Tracker Save Session Session" src={require("./images/reading_tracker_save_session.png")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      